import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Vue, Watch } from "vue-property-decorator";
import DfProduct from "@/vue/domain/product/df-product";
import DfPromotion from "@/vue/domain/promotion/df-promotion";

const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfCardProductComponent = () => Utils.externalComponent2("df-card-product");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfCardProduct: DfCardProductComponent,
    DfProductDetailsData: () => Utils.externalComponent2("df-product-datails-data"),
    DfProductDetailsRelatedProducts: () => Utils.externalComponent2("df-product-datails-related-products"),
    DfProductDetailsExtraContent: () => Utils.externalComponent2("df-product-datails-extra-content"),
    DfProductDetailsImages: () => Utils.externalComponent2("df-product-details-images"),
  },
})
export default class DfPageProductComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  get currentProduct(): DfProduct {
    return this.cachedProducts.find((product: DfProduct) => product.alias === this.$route.params.productAlias) || null;
  }

  get allPromotions(): Array<DfPromotion> {
    return this.$store.getters.allPromotions;
  }

  get promotion(): DfPromotion {
    return this.allPromotions.find((promotion: DfPromotion) => promotion.alias === this.$route.params.promotionAlias);
  }

  get promotionsLoaded(): boolean {
    return this.$store.getters.promotionsLoaded;
  }

  get hash(): string {
    return this.$route.hash || null;
  }

  get showBreadcrumb(): boolean {
    return Utils.showBreadcrumb();
  }

  get productTitle(): string {
    const title: string = Utils.getPropertyValue(this.currentProduct, Utils.PROPERTY_TITLE, "STRING");
    const description: string = Utils.getPropertyValue(this.currentProduct, Utils.PROPERTY_PRODUCT_DESCRIPTION, "STRING");
    const longDescription: string = Utils.getPropertyValue(this.currentProduct, Utils.PROPERTY_PRODUCT_LONG_DESCRIPTION, "TEXT");

    return title || description || longDescription || this.currentProduct.description || "";
  }

  @Watch("currentProduct", { immediate: true })
  currentProductChange() {
    document.title = this.currentProduct ? `${this.productTitle} | ${Utils.APP_NAME}` : "";
  }

  @Watch("promotion", { immediate: true })
  promotionChange(newPromotion: DfPromotion, oldPromotion: DfPromotion) {
    const rule01 = !!newPromotion;
    const rule02: boolean = newPromotion && oldPromotion ? newPromotion.alias !== oldPromotion.alias : true;
    if (rule01 && rule02) {
      this.$root.$emit("loadCachedProduct", [this.$route.params.productAlias], this.redirectToHome);
    }
  }

  @Watch("promotionsLoaded", { immediate: true })
  promotionLoadedChange() {
    if (this.promotionsLoaded && !this.promotion) {
      this.redirectToHome();
    }
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private redirectToHome() {
    if (!this.currentProduct || !this.promotion) {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  private goBack() {
    Utils.goBack(this);
  }
}
